export default {
  initialColorModeName: 'light',
  colors: {
    text: "#333",
    background: "#fff",
    primary: "#639",
    modes: {
      dark: {
        text: "#fff",
        background: "#fff",
        primary: "#A5F",
      }
    }
  }
}